
.inline-container {
    display: flex;
    align-items: center; /* This aligns children vertically in the center */
}


.inline-container p {
    padding: 0;
    margin: 0;
    color: black;
}

/* Additional styles for button if necessary */
.btn-1 {
    margin-left: 1.5em;
    
}

.about {
    /* padding-top: 2em; */
    align-items: center;
    display: flex;
    justify-content: center; 
       
}

.about p body div {
  font-size: 0.8em;
    letter-spacing: .8px;
    /* line-height: 25px; */
    color: #000;
}

.bio {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-grow: 2;
    color: black;
    /* font-family: 'Roboto Mono', monospace; */

}


.links {
    display: flex;
    flex-direction: column;
}



 .row {
    margin-right: 1em;
    padding-right: 1em;
    border-right: 1px solid #000; /* Specify the width and color of the left border */
  font-size: 0.8em;

}


body, p {
    font-weight: 400;
    letter-spacing: .8px;
    line-height: 25px;
    color: #6c757d; /* You can also use 'white' or '#000' for black color */

}


.header {
    padding-top: 1em; 
    font-size: 1.5em;
    text-align: start;

}

.landing--social {
  font-size: 35px;
    color: black; /* You can also use 'white' or '#000' for black color */

}

.row {
    text-align: start;

}

.row-doing {
    padding-top: 0.5em;
}

.row-pad {
    padding-top: 0.7em;
}