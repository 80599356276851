
body {
  font-family: 'Roboto Mono', monospace;
  /* font-family: "Century Gothic", 'Lato', sans-serif; */

}

.container-app {

  margin-left: 15%;
  margin-right: 15%;
}


#about, #education, #experience {
    padding-top: 30px; /* Adjust as needed */
}

h2 {
    font-weight: 400;
    font-size: 1.5em;
    text-align: start;
    padding: 0.5em;
    width: 60%;
    margin: 0;
    color: #495057;
    
}

h3 {
    font-weight: 400;
    font-size: 1em;
    text-align: center;
    padding: 0.5em;
    margin: 0;
    color: #495057;
}

body, p {
    font-weight: 300;
    letter-spacing: .8px;
    line-height: 25px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer {
  font-weight: 300; /* or 100, 200, etc. */
  font-size: 0.9em;
  margin: 0.4em;
} 

.footer-container {
  margin-bottom: 1.5em;
}

.App {
  /* background-color: lightgrey; */
}