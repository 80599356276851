.et-hero-tabs-container {
    display: flex;
    flex-direction: row;
    position: sticky;
    top: 0;
    width: 100%;
    height: 70px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    background: #fff;
    z-index: 10;
    transition: all 0.3s ease;
}

.et-hero-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    color: #000;
    letter-spacing: 0.1rem;
    transition: all 0.5s ease;
    font-size: 0.8rem;
}

.et-hero-tab:hover {
    color: white;
    background: #6c757d;
    transition: all 0.5s ease;
}