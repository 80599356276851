.Landing {
    z-index: 1;
      position: absolute;


    /* background-image: url('../assets/codePen.png');    */
	opacity: 0.8; /* Adjust the opacity as needed */
    /* background-size: contain;  or 'contain' based on your preference */
    background-position: center;
    background-repeat: no-repeat;
	font-family: 'Poppins', sans-serif;
    display: flex;
	background: #e9ecef;
  background: linear-gradient(to right, #e9ecef, #fff, #e9ecef);
background: linear-gradient(to bottom, #e9ecef, #fff, #e9ecef);


    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 65vh;
    position: relative;
		text-align: center;
		padding: 0 2em;
    h1 {
        font-size: 2.5rem;
        margin: 0;
        letter-spacing: 1rem;
    }
    h3 {
        font-size: 2rem;
        letter-spacing: 0.3rem;
        opacity: 0.6;
    }
}

p {
	text-align: center;
}

.banner-logo {
  z-index: 2;
    position: absolute;
}

.banner-logo img {
  width: 100px; /* Adjust the width as needed */
  padding-bottom: 8em;
  padding-left: 3em;
  filter: grayscale(100%) brightness(100%);
}

.row1 {
  font-size: 3rem;
  opacity: 1;
  color: black;
  filter: drop-shadow(0px 0px 7px rgba(150, 150, 150, .7));
}

.experience {
  padding-top: 20px;
}

.experience-section {
  max-width: 80%;
  margin: 2px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.header-section {
  padding: 10px;
}

.experience-row {
  margin-bottom: 20px;
  border:2px solid #f4f4f4;
}

.row2 {
	  opacity: 1;
}
