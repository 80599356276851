
.skillsContainer {
  display: flex;
    flex-wrap: wrap;

  align-items: center;
  justify-content: start;
  width: 100%;
  margin-top: 1rem;
}

.skill--box {
  background: #fafafa;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  width: 100px;
  height: 100px;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  transition: 300ms ease-in-out;
}

.skill--box:hover {
  transform: scale(1.15);
}

.skill--box > img {
  height: 40px;
  pointer-events: none;
}

.skill--box h3 {
  /* font-family: Big Shoulders Text; */
  font-style: normal;
  font-weight: 500;
  font-size: 1.2em;
  text-align: center;
  margin-top: 0.2rem;
}


@media screen and (max-width: 992px) {
  .skills {
    padding: 1rem;
    min-height: 100%;
  }
  .skillsContainer {
    padding: 1rem;
    margin: 1rem 0;
  }
  .skillsHeader h2 {
    font-size: 3.2rem;
  }
  .skill--box {
    width: 150px;
    height: 150px;
    margin: 1.2rem;
    padding: 2rem 1rem;
  }

  .skill--box > img {
    height: 45px;
  }

  .skill--box h3 {
    font-size: 20px;
    margin-top: 1rem;
  }
}


@media screen and (max-width: 600px) {
  .skill--box {
    width: 135px;
    height: 135px;
    margin: 1.2rem;
    padding: 2rem 1rem;
  }

  .skill--box > img {
    height: 40px;
  }

  .skillsHeader h2 {
    font-size: 2.5rem;
  }

  .skill--box h3 {
    font-size: 18px;
    margin-top: 1rem;
  }
}
